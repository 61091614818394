<template>
  <div class="section">
    <new-search
      ref="searchRef"
      search-type="titleName"
      placeholder="搜索企业名称"
      @getLists="refreshLoad"
    />
    <div class="search_list">
      <ul class="search-ul">
        <error-page
          v-if="!listData.length"
          class="errorHeight"
        />
        <li
          v-for="(item, index) of listData"
          :key="index"
          class="search-li"
          @click="choose(item, index)"
        >
          <div
            class="search-li-name"
          >
            <span class="search-departName">{{ item.title }}</span>
          </div>
          <i
            v-if="initialIndex === index"
            class="icon-joyo search-li-icon"
          >&#xe728;</i>
        </li>
      </ul>
    </div>
    <div class="bottom-postion btn-return-other">
      <cube-button
        :light="true"
        class="btn-return"
        @click="$router.go(-1)"
      >
        返回
      </cube-button>
      <cube-button
        type="submit"
        class="btn-other"
        @click="toAdd"
      >
        新增企业
      </cube-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { myCustWithBusOppoList } from '_api/global';
import { bindRelation } from '_api/qycust';
import errorPage from '_c/errorPage';
import newSearch from '_c/search/newSearch';
export default {
  name: 'AssociatedCompanies',
  components: {
    newSearch,
    errorPage
  },
  data() {
    return {
      listData: [],
      listAllData: [],
      initialIndex: ''
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'externalUserid'])
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.listData = [];
      this.listAllData = [];
      myCustWithBusOppoList().then(res => {
        if (res.data.myCustWithBusOppoList.length) {
          res.data.myCustWithBusOppoList.forEach(item => {
            this.listData.push(
              {
                title: item.custCn,
                custId: item.custId
              }
            );
            this.listAllData = this.listData;
          });
        }
      });
    },
    refreshLoad(val) {
      this.listData = [];
      if (val !== '') {
        myCustWithBusOppoList(val).then(res => {
          res.data.myCustWithBusOppoList.forEach(item => {
            this.listData.push(
              {
                title: item.custCn,
                custId: item.custId
              }
            );
          });
        });
      } else {
        this.listData = this.listAllData;
      }
    },
    toAdd() {
      this.$router.push(
        {
          name: 'AddEnterprise'
        }
      );
    },
    choose(data, index) {
      this.initialIndex = index;
      this.associatedCompanies(data.custId);
    },
    associatedCompanies(custId) {
      bindRelation(this.externalUserid, custId, this.userInfo.userInfoRes.weChatUserId).then(res => {
        if (res.flag) {
          this.$showToast('保存并关联成功');
          this.$router.push(
            {
              name: 'QycustDetail'
            }
          );
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style  lang="scss"  scoped>
.section{
  background-color:$color-FFF ;
}
.search_list{
  z-index: 999;
  width:100%;
  height: calc(100vh - 128px);
  overflow: scroll;
  background-color:$color-FFF;
  .search-ul{
    background-color: $color-FFF;
    border-top:1px solid $color-E5;
    border-bottom:1px solid $color-E5;
    .search-li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin:0 15px;
      padding: 10px 0;
      border-bottom:1px solid $color-E5;
      .search-li-name {
        display: flex;
        justify-content: flex-start;
        color: $color-333;
        font-size: $font-15;
        .search-departName{
          padding: 10px;
          text-align: left;
        }
      }
    }
    .search-li:last-child{
      border: 0;
    }
  }
}
.search-li-icon {
  margin-right: 14px;
  color: $color-font;
  font-size: $font-14;
}
.errorHeight{
  height:calc(100vh - 128px);
}
</style>
